import "./bootstrap";
import "../css/all.css";
import.meta.glob(["../images/**"]);
import * as Sentry from "@sentry/react";

import { createRoot } from "react-dom/client";
import { createInertiaApp } from "@inertiajs/react";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";

const appName =
  window.document.getElementsByTagName("title")[0]?.innerText || "Laravel";

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) =>
    resolvePageComponent(
      `./Pages/${name}/index.tsx`,
      import.meta.glob("./Pages/**/*.tsx"),
    ),
  setup({ el, App, props }) {
    const root = createRoot(el);

    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
      environment: import.meta.env.APP_ENV,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      // Performance Monitoring
      tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE, // 0-1.0  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: import.meta.env.VITE_SENTRY_REPLAY_SAMPLE_RATE,
      replaysOnErrorSampleRate: import.meta.env
        .VITE_SENTRY_ERROR_REPLAY_SAMPLE_RATE, // 0-1.0
    });

    root.render(<App {...props} />);
  },
  progress: {
    color: "#4B5563",
  },
});
